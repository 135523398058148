import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { get } from "lodash";
import React, { memo } from "react";
import "./styles.css";

function Reason({ lang }) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          reason {
            title
            contents {
              title
              id
            }
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    placeholder: BLURRED
                    pngOptions: { compressionSpeed: 10, quality: 10 }
                    layout: CONSTRAINED
                  )
                }
              }
              alternativeText
            }
          }
        }
      }
    }
  `);
  const reasonData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];
  return (
    <Container
      maxWidth="1440px"
      bgColor="#fff"
      py="60px"
      className="reason-container"
    >
      <Flex justifyContent="center" alignItems="center" flexDir="column">
        <Heading
          textAlign="center"
          fontSize={{ base: "24px", sm: "32px", lg: "36px" }}
          mb="20px"
          color="#333"
        >
          {get(reasonData, "reason.title", "")}
        </Heading>
        <Divider
          color="primary"
          borderWidth="1px"
          borderColor="primary"
          width="20%"
        />
      </Flex>
      <Box pos="relative" margin="40px 0" textAlign="center" width="100%">
        <GatsbyImage
          image={getImage(reasonData?.reason?.cover?.localFile)}
          alt={get(reasonData, "reason.cover.alternativeText", "")}
        />
        <Box as="ul">
          {reasonData?.reason?.contents?.map((item, index) => (
            <Box
              as="li"
              key={item.id}
              display="block"
              position={{ base: "relative", lg: "absolute" }}
              textAlign={{ base: "left", lg: "right" }}
              marginTop={{ base: "10px", lg: 0 }}
              width={{ base: "100%", lg: "360px" }}
              fontWeight={600}
              fontSize="14px"
              className="reason-item"
            >
              {isMobile ? (
                <Flex alignItems="center">
                  <CheckIcon fontSize={18} color="green.300" />
                  <Text ml="10px">{item.title}</Text>
                </Flex>
              ) : (
                item.title
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
}

export default memo(Reason);
