import { PhoneIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import React from "react";
import keyFramesList from "../../../common/theme/keyframe";

const Hotline = ({ phone }) => {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box
          as="a"
          href="tel:+84971345732"
          width="60px"
          height="60px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={8}
          bgColor="red"
          borderRadius="50%"
          position="relative"
          _before={{
            content: '""',
            position: "absolute",
            border: "1px solid red",
            width: "calc(100% + 20px)",
            height: "calc(100% + 20px)",
            borderRadius: "50%",
            bgColor: "rgba(245, 39, 39, 0.22)",
            borderColor: "rgba(245, 39, 39, 0.22)",
            animation: `${keyFramesList.pulse} 1s linear infinite`,
          }}
          _after={{
            content: '""',
            position: "absolute",
            border: "1px solid RED",
            width: "calc(100% + 40px)",
            height: "calc(100% + 40px)",
            borderRadius: "50%",
            animation: `${keyFramesList.pulse} 1s linear infinite`,
            animationDelay: "0.3s",
          }}
        >
          <PhoneIcon
            color="white"
            boxSize={{ base: "10", sm: "8" }}
            animation={`${keyFramesList.ring} 2s ease infinite`}
          />
        </Box>
      </Box>
    </>
  );
};

export default Hotline;
