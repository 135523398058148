import React from "react";
import { Icon } from "react-icons-kit";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Container from "common/components/UI/Container";
import { Link, useStaticQuery, graphql } from "gatsby";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import Section, { SectionHeading, Grid } from "./howItWorks.style";
import { get, isEmpty } from "lodash";
import {
  Box,
  Flex,
  Heading as CHeading,
  Link as CLink,
} from "@chakra-ui/react";
import LazyLoadImage from "common/components/LazyLoadImage";

const HowItWorks = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          howItWorks {
            title
            cards {
              id
              title
              learnMoreUrl
              description
              icon {
                url
                alternativeText
              }
              linkText
            }
          }
        }
      }
    }
  `);
  const cmsData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  return (
    <Section id="how-it-works">
      <Container width="1400px">
        <SectionHeading>
          <Heading content={get(cmsData, "howItWorks.title", "")} />
        </SectionHeading>
        <Grid>
          {!isEmpty(cmsData?.howItWorks?.cards) &&
            cmsData?.howItWorks?.cards?.map((item) => (
              <Flex
                key={item.id}
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box width="80px" mb="10px">
                  <LazyLoadImage
                    src={item?.icon?.url ?? ""}
                    widthPx={120}
                    srcsetSizes={[
                      { imageWidth: 200, viewPortWidth: 992 },
                      { imageWidth: 200, viewPortWidth: 768 },
                      { imageWidth: 200, viewPortWidth: 500 },
                    ]}
                    alt={item?.icon?.url?.alternativeText ?? ""}
                  />
                </Box>
                <CHeading fontWeight={500} fontSize={20} mt="10px">
                  {item?.title}
                </CHeading>
                <Text content={item?.description} />
                <CLink
                  as={Link}
                  isExternal
                  color="primary"
                  to={get(item, "learnMoreUrl", "/")}
                >
                  {item?.linkText} <Icon icon={arrowRight} />
                </CLink>
              </Flex>
            ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default HowItWorks;
