import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const Title = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 0;
    color: ${themeGet("colors.black")};
    @media (max-width: 768px) {
      text-align: center;
      font-size: 24px;
    }
  }
`;

export const Item = styled.div`
  text-align: center;
  figure {
    margin: 0 0 40px;
    min-height: 110px;
    @media (min-width: 769px) and (max-width: 1024px) {
      min-height: auto;
      margin-bottom: 30px;
    }
    @media (max-width: 480px) {
      margin-bottom: 20px;
      min-height: auto;
    }
  }

  img {
    margin: 0 auto;
    @media (max-width: 1024px) {
      max-width: 120px;
    }
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    @media (max-width: 1024px) {
      font-size: 17px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  p {
    color: ${themeGet("colors.textColor")};
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 15px;
      line-height: 1.9;
    }
    @media (max-width: 480px) {
      font-size: 15px;
    }
  }
  a {
    color: ${themeGet("colors.linkColor")};
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.1px;
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 14px;
    }
    i {
      line-height: 1;
      transform: translateX(2px);
      transition: 0.3s ease 0s;
    }
    &:hover i {
      transform: translateX(5px);
    }
  }
`;

export const SliderWrapper = styled.div`
  margin-top: 45px;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  .slider {
    display: flex;
    justify-content: space-between;
  }
  figure {
    img {
      opacity: 0.7;
    }
  }
  .slick-slide > div {
    display: flex;
    min-height: 35px;
    align-items: center;
    img {
      margin: 0 auto;
    }
  }
  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    button {
      background-color: rgba(0, 0, 0, 0.2);
      border: 0;
      border-radius: 20px;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      text-indent: -9999em;
    }
    .slick-active button {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
`;

export default Section;
