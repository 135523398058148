import React, { memo } from "react";
import { Box } from "@chakra-ui/react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Hotline from "./Hotline";

function RightContact({ phone }) {
  return (
    <Box>
      <Box position="fixed" right="26px" bottom="115px" zIndex={99999}>
        <Hotline phone={phone} />
      </Box>
      <div
        className="zalo-chat-widget"
        data-oaid="953802030287535291"
        data-welcome-message="KAIO xin chào anh/chị, anh/chị đang quan tâm đến thông tin nào ạ?"
        data-autopopup="0"
        data-width="350"
        data-height="420"
      ></div>

      <MessengerCustomerChat pageId="105761675110065" appId="737662793844914" />
    </Box>
  );
}

export default memo(RightContact);
