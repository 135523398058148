import React from "react";
import { Icon } from "react-icons-kit";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql, Link } from "gatsby";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import { Section, SectionHeading, Grid, Article } from "./newsFeed.style";
import { get } from "lodash";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NewsFeed = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(query);
  const viPosts = allStrapiHomePage?.nodes?.filter(
    (item) => item.locale === lang
  )[0];
  return (
    <Section id="newsfeed">
      <Container width="1400px">
        <SectionHeading>
          <Heading content={viPosts?.newsfeed?.title} />
        </SectionHeading>
        <Grid>
          {viPosts?.newsfeed?.newsfeeds?.map((p, index) => {
            return (
              <Fade key={p.id} up delay={index * 100}>
                <Article>
                  <GatsbyImage
                    image={getImage(get(p, "card.image.localFile", ""))}
                    alt={p?.card?.image?.alternativeText}
                    objectFit="cover"
                  />
                  <Text content={p?.card?.timePublic} />
                  <Heading as="h4" content={p?.card?.title} />
                  <Link to={p?.card?.slug}>
                    Read More <Icon icon={arrowRight} />
                  </Link>
                </Article>
              </Fade>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};

export default NewsFeed;

export const query = graphql`
  {
    allStrapiHomePage {
      nodes {
        locale
        newsfeed {
          title
          newsfeeds {
            id
            card {
              id
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      placeholder: BLURRED
                      pngOptions: { compressionSpeed: 10, quality: 10 }
                      layout: CONSTRAINED
                    )
                  }
                }
                alternativeText
                width
              }
              slug
              timePublic(formatString: "DD/MM/YYYY")
              title
            }
          }
        }
      }
    }
  }
`;
