import React, { Fragment, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { theme } from "common/theme/webAppCreative";
import { ResetCSS } from "common/assets/css/style";
import Navbar from "containers/WebAppCreative/Navbar";
import Banner from "containers/WebAppCreative/Banner";
import HowItWorks from "containers/WebAppCreative/HowItWorks";
import AnalyticsTool from "containers/WebAppCreative/AnalyticsTool";
import Features from "containers/WebAppCreative/Dashboard";
import Testimonials from "containers/WebAppCreative/Testimonials";
import Integrations from "containers/WebAppCreative/Integrations";
import Pricing from "containers/WebAppCreative/Pricing";
import NewsFeed from "containers/WebAppCreative/NewsFeed";
import Faq from "containers/WebAppCreative/Faq";
import CallToAction from "containers/WebAppCreative/CallToAction";
import Footer from "containers/WebAppCreative/Footer";
import Clients from "containers/WebAppCreative/Clients";
import Seo from "components/seo";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/WebAppCreative/webAppCreative.style";
import { get } from "lodash";
import { useI18next } from "gatsby-plugin-react-i18next";
import NumberImpression from "../components/Landing/NumberImpression/NumberImpression";
import Reason from "../components/Landing/Reason/Reason";
import RightContact from "../common/components/RightContact/RightContact";

const WebAppCreative = () => {
  const { language: lang } = useI18next();

  const { site, allStrapiHomePage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          supportedLanguages
          siteUrl
        }
      }
      allStrapiHomePage {
        nodes {
          header {
            logo {
              image {
                url
              }
              alt
            }
          }
          seo {
            author
            description
            title
            shareImage {
              alternativeText
              url
            }
            keyworks {
              keyword
              id
            }
          }
          footer {
            contactInfo {
              phone
            }
          }
          locale
        }
      }
    }
  `);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const cmsData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo
          title={get(cmsData, "seo.title", "")}
          host={site?.siteMetadata?.siteUrl}
          description={get(cmsData, "seo.description", "")}
          ogImage={get(cmsData, "seo.shareImage.url", "")}
          keywords={get(cmsData, "seo.keyworks", [])}
          author={get(cmsData, "seo.author", "")}
          supportedLanguages={get(site, "siteMetadata.supportedLanguages", [])}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={888} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar
                logo={get(cmsData, "header.logo.image.url", "")}
                lang={lang}
                supportedLanguages={get(
                  site,
                  "siteMetadata.supportedLanguages",
                  []
                )}
              />
            </DrawerProvider>
          </Sticky>
          <Banner lang={lang} />
          <Features lang={lang} />
          <AnalyticsTool lang={lang} />
          <Reason lang={lang} />
          <Integrations lang={lang} />
          <Clients lang={lang} />
          <NumberImpression lang={lang} />
          <Testimonials lang={lang} />
          <HowItWorks lang={lang} />
          <Pricing lang={lang} />
          <NewsFeed lang={lang} />
          <Faq lang={lang} />
          <CallToAction lang={lang} />
          <Footer lang={lang} />
          <RightContact phone={cmsData?.footer?.contactInfo?.phone} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default WebAppCreative;
