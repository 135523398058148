import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { ic_keyboard_arrow_right } from "react-icons-kit/md/ic_keyboard_arrow_right";
import LazyLoadImage from "common/components/LazyLoadImage";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Text from "common/components/Text";
import {
  Section,
  SectionHeading,
  SwitcherWrapper,
  Grid,
  PriceTable,
} from "./pricing.style";
import { get } from "lodash";
import ModalForm from "../../../components/ModalForm/ModalForm";
import { useDisclosure } from "@chakra-ui/react";

const Pricing = ({ lang }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          pricingHeader {
            title
            monthOfYear
          }
          pricingContents {
            button {
              name
              url
            }
            currencySymbol
            icon {
              image {
                url
              }
              alt
            }
            id
            isActive
            includeLink {
              url
              name
            }
            packDescription
            packTitle
            price {
              annual
              monthly
            }
          }
        }
      }
    }
  `);

  const priceData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  useEffect(() => {
    setIsMonthly(get(priceData, "pricingHeader.monthOfYear", false));
  }, [priceData]);

  const openModal = () => {
    onOpen();
  };

  return (
    <Section id="pricing">
      <Container width="1400px">
        <SectionHeading>
          <Heading content={get(priceData, "pricingHeader.title", "")} />
        </SectionHeading>
        <SwitcherWrapper>
          <span className={isMonthly ? "active label" : "label"}>Monthly</span>
          <button className="switcher" onClick={handleToggle}>
            <span
              className={`switcher-button ${isMonthly ? "left" : "right"}`}
            />
          </button>
          <span className={!isMonthly ? "active label" : "label"}>Yearly</span>
        </SwitcherWrapper>
        <Grid>
          {priceData?.pricingContents?.map((priceTable, index) => (
            <Fade key={priceTable?.id} up delay={index * 100}>
              <PriceTable
                className={
                  priceTable.isActive
                    ? "active animate__animated animate__fadeInUp"
                    : "animate__animated animate__fadeInUp"
                }
              >
                <Heading
                  content={`${priceTable.currencySymbol === "VND" ? "" : "$"} ${
                    !isMonthly
                      ? `${priceTable?.price?.annual} ${
                          priceTable.currencySymbol === "VND" ? "VND" : ""
                        }`
                      : `${priceTable?.price?.monthly} ${
                          priceTable.currencySymbol === "VND" ? "VND" : ""
                        }`
                  }`}
                />
                <Heading as="h5" content={priceTable?.packTitle} />
                <Text content={priceTable?.packDescription} />
                <figure>
                  <LazyLoadImage
                    widthPx={120}
                    srcsetSizes={[
                      { imageWidth: 200, viewPortWidth: 992 },
                      { imageWidth: 200, viewPortWidth: 768 },
                      { imageWidth: 200, viewPortWidth: 500 },
                    ]}
                    src={priceTable?.icon?.image?.url}
                    alt={priceTable?.icon?.image?.alt}
                  />
                </figure>
                <Button
                  title={get(priceTable, "button.name", "")}
                  onClick={openModal}
                />
                <a
                  className="link"
                  href={get(priceTable, "includeLink.url", "#")}
                >
                  {get(priceTable, "includeLink.name", "")}{" "}
                  <Icon size={20} icon={ic_keyboard_arrow_right} />
                </a>
              </PriceTable>
            </Fade>
          ))}
        </Grid>
      </Container>
      <ModalForm isOpen={isOpen} onClose={onClose} lang={lang} />
    </Section>
  );
};

export default Pricing;
