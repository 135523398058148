import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import Counter from "../Counter/Counter";
import LazyLoadImage from "common/components/LazyLoadImage";

function NumberImpression({ lang }) {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          impression {
            StatisticalInfo {
              id
              quantity
              content
              icon {
                url
                alternativeText
              }
            }
            title
          }
        }
      }
    }
  `);

  const data = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];
  return (
    <Container maxWidth="1600px" my="50px">
      <Box
        as="h2"
        textAlign="center"
        color="#333"
        fontWeight={600}
        fontFamily="Inter,sans-serif"
        mt={{ base: "30px" }}
        fontSize={{ base: "24px", sm: "32px", lg: "36px" }}
      >
        {data?.impression?.title}
      </Box>

      <SimpleGrid
        columns={{ base: 1, sm: 2, lg: 3 }}
        gap="20px"
        my="40px"
        mx="20px"
      >
        {data?.impression?.StatisticalInfo?.map((item) => (
          <Box
            as="li"
            display="flex"
            alignItems="center"
            key={item.id}
            color="primary"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: "60px", md: "104px" }}
              h={{ base: "60px", md: "104px" }}
              borderRadius="50%"
              boxShadow="0px 2px 6px rgb(187 199 206 / 50%)"
              background="linear-gradient(
    0deg,
    rgba(35, 89, 190, 1) 0%,
    rgba(44, 111, 238, 0.8995973389355743) 100%
  )"
              mr="16px"
            >
              <LazyLoadImage
                widthPx={80}
                srcsetSizes={[
                  { imageWidth: 250, viewPortWidth: 992 },
                  { imageWidth: 250, viewPortWidth: 768 },
                  { imageWidth: 250, viewPortWidth: 500 },
                ]}
                src={item?.icon?.url}
                alt={item?.icon?.alternativeText}
              />
            </Box>
            <Box>
              <Box
                fontSize={{ base: "24px", lg: "32px" }}
                lineHeight="46px"
                fontWeight="500"
              >
                <Counter maxCount={item?.quantity} duration="10" label="+" />
              </Box>
              <Box
                as="p"
                fontSize="16px"
                lineHeight="24px"
                fontWeight="500"
                color="black"
              >
                {item?.content}
              </Box>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  );
}

export default memo(NumberImpression);
