import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
  useToast,
  Input as InputChakra,
  InputGroup,
  InputLeftElement,
  Box,
} from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import Button from "common/components/Button";
import Section, {
  BannerContentWrapper,
  BannerContent,
  Subscribe,
  Figure,
} from "./banner.style";
import { get } from "lodash";
import axios from "axios";
import { defaultParams } from "../../../common/hooks/useGetUtm";

const Banner = ({ lang }) => {
  const [phone, setPhone] = useState("");
  const toast = useToast();

  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          sologan {
            title
            description
          }
          yourWorkEmail {
            button {
              name
            }
            placeholder
            icon {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                alternativeText
              }
            }
          }
        }
      }
    }
  `);

  const viData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  const handleChangePhone = (event) => {
    const { value } = event.target;
    setPhone(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const urlSearchParams = !!window.location.search
      ? new URLSearchParams(window.location.search)
      : "";
    const params = !!urlSearchParams
      ? Object.fromEntries(urlSearchParams.entries())
      : defaultParams;

    if (!!phone) {
      try {
        const result = await axios.post(
          "https://cms.kaio.ai/api/phone-contacts",
          {
            data: {
              phone,
              ...params,
            },
          }
        );
        if (result.data !== null && !!result.data) {
          toast({
            title:
              "Thank you for your interest in Kaio. We will contact you soon.",
            status: "success",
            position: "top-right",
          });
          setPhone("");
        } else {
          toast({
            title: "Có lỗi xảy ra",
            status: "error",
            position: "top-right",
          });
        }
      } catch (err) {
        toast({
          title: "Có lỗi xảy ra",
          status: "error",
          position: "top-right",
        });
      }
    }
  };

  return (
    <div>
      <Section id="home">
        <Container width="1400px">
          <BannerContentWrapper>
            <BannerContent>
              <Heading
                className="animate__animated animate__fadeInUp"
                content={viData?.sologan?.title}
              />
              <Text
                className="animate__animated animate__fadeInUp"
                content={viData?.sologan?.description}
              />
              <form onSubmit={handleSubmit}>
                <Subscribe className="animate__animated animate__fadeInUp">
                  <InputGroup
                    bgColor="#f1f4f6"
                    borderRadius="8px"
                    height="60px"
                    fontSize="16px"
                    alignItems="center"
                  >
                    <InputLeftElement
                      pointerEvents="none"
                      height="100%"
                      children={<PhoneIcon fontSize={18} color="#3E4E5E" />}
                    />
                    <InputChakra
                      type="tel"
                      name="phone"
                      value={phone}
                      placeholder={get(viData, "yourWorkEmail.placeholder", "")}
                      isRequired={true}
                      pattern="[0-9]{10}"
                      onChange={handleChangePhone}
                      height="100%"
                      width="100%"
                      border="none"
                      px="24px"
                      ml="10px"
                      focusBorderColor="none"
                    />
                  </InputGroup>
                </Subscribe>
                <Box mt="20px">
                  <Button
                    title={get(viData, "yourWorkEmail.button.name", "")}
                    type="submit"
                    style={{ borderRadius: "8px" }}
                  />
                </Box>
              </form>
            </BannerContent>
            <Figure className="animate__animated animate__fadeInUp animate__fast">
              <GatsbyImage
                image={getImage(viData?.yourWorkEmail?.icon?.image.localFile)}
                alt={get(
                  viData,
                  "yourWorkEmail.icon.image.alternativeText",
                  ""
                )}
              />
            </Figure>
          </BannerContentWrapper>
        </Container>
      </Section>
    </div>
  );
};

export default Banner;
