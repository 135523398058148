import React, { useState, Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/entypo/plus";
import { minus } from "react-icons-kit/entypo/minus";
import Section, { SectionHeading, RcCollapse } from "./faq.style";
import { Panel } from "rc-collapse";
import motion from "./motion-util";
import { get } from "lodash";
import "rc-collapse/assets/index.css";

const Faq = ({ lang }) => {
  const [activeKey, setActiveKey] = useState(1);

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          faq {
            faqs {
              id
              question
              answer
            }
            title
          }
        }
      }
    }
  `);
  const data = allStrapiHomePage?.nodes?.filter(
    (node) => node.locale === lang
  )[0];
  return (
    <Section id="faq">
      <Container className="container">
        <SectionHeading>
          <Heading content={get(data, "faq.title", "")} />
        </SectionHeading>
        <RcCollapse
          accordion={true}
          activeKey={activeKey}
          onChange={onChange}
          openMotion={motion}
          expandIcon={({ isActive }) => (
            <Icon icon={plus} size={20} className="plus" />
          )}
        >
          {get(data, "faq.faqs", [])?.map((faq) => (
            <Panel
              key={faq.id}
              showArrow={false}
              header={
                <>
                  <Heading as="h4" content={faq.question} />
                  <span className="icon">
                    <Icon icon={minus} size={20} className="minus" />
                    <Icon icon={plus} size={20} className="plus" />
                  </span>
                </>
              }
            >
              {faq?.answer}
            </Panel>
          ))}
        </RcCollapse>
      </Container>
    </Section>
  );
};

export default Faq;
