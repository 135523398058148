import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import LazyLoadImage from "common/components/LazyLoadImage";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Section, { SectionHeading, SupportedApps } from "./integration.style";
import { get } from "lodash";

const Integrations = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          integration {
            title
            description
            apps {
              id
              name
              icon {
                url
                alternativeText
              }
              bgColor
              isBulurred
            }
          }
        }
      }
    }
  `);

  const data = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  return (
    <Section>
      <Container width="1400px">
        <SectionHeading>
          <Heading content={get(data, "integration.title", "")} />
          <Text content={get(data, "integration.description", "")} />
        </SectionHeading>
        <SupportedApps>
          {get(data, "integration.apps", []).map((app) => (
            <figure
              key={app.id}
              className={app.isBulurred ? "blurred" : undefined}
              style={{ backgroundColor: app.bgColor ?? undefined }}
            >
              <LazyLoadImage
                widthPx={120}
                srcsetSizes={[
                  { imageWidth: 200, viewPortWidth: 992 },
                  { imageWidth: 200, viewPortWidth: 768 },
                  { imageWidth: 200, viewPortWidth: 500 },
                ]}
                src={get(app, "icon.url", "")}
                alt={get(app, "icon.alternativeText", "")}
              />
            </figure>
          ))}
        </SupportedApps>
      </Container>
    </Section>
  );
};

export default Integrations;
