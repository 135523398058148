import React from "react";
import Fade from "react-reveal/Fade";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import {
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Text as CText,
  Flex,
} from "@chakra-ui/react";
import Section, { SectionHeading } from "./dashboard.style";
import { get } from "lodash";
import { useStaticQuery, graphql } from "gatsby";
import LazyLoadImage from "common/components/LazyLoadImage";

const Features = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          features {
            description
            title
            id
            features {
              id
              image {
                url
              }
              imageAlt
              name
            }
          }
        }
      }
    }
  `);
  const featuresData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];
  return (
    <Section id="features">
      <Container maxW="1440px">
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
          gap={10}
        >
          <GridItem colSpan={{ base: 1, lg: 9 }}>
            <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} gap="20px">
              {get(featuresData, "features.features", []).map((tab) => (
                <Fade up key={tab.id}>
                  <Flex
                    flexDir="column"
                    minH="150px"
                    h="100%"
                    bgColor="#2359be"
                    borderRadius="4px"
                    p="10px"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    className="feature-tab"
                  >
                    <LazyLoadImage
                      widthPx={60}
                      srcsetSizes={[
                        { imageWidth: 60, viewPortWidth: 992 },
                        { imageWidth: 50, viewPortWidth: 768 },
                        { imageWidth: 40, viewPortWidth: 500 },
                      ]}
                      src={get(tab, "image.url", "")}
                      alt={tab?.imageAlt}
                    />
                    <CText
                      color="#fff"
                      fontWeight={500}
                      textAlign="center"
                      mt="50px"
                    >
                      {tab?.name}
                    </CText>
                  </Flex>
                </Fade>
              ))}
            </SimpleGrid>
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 3 }} order={{ base: -1, lg: 1 }}>
            <Fade up>
              <SectionHeading>
                <Heading content={get(featuresData, "features.title", "")} />
                <Text content={get(featuresData, "features.description", "")} />
              </SectionHeading>
            </Fade>
          </GridItem>
        </Grid>
      </Container>
    </Section>
  );
};

export default Features;
