import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import parse from "html-react-parser";

import Section, {
  SectionHeading,
  ReactSlick,
  Item,
} from "./testimonials.style";
import "common/assets/css/react-slick.css";
import { get } from "lodash";
import {
  Box,
  Flex,
  Heading as CHeading,
  Text as CText,
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Testimonials = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          testimonials {
            title
            testimonials {
              id
              content {
                data {
                  content
                }
              }
              testimonialPeopleName
              testimonialPeoplePosition
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: WEBP)
                  }
                }
                alternativeText
              }
            }
          }
        }
      }
    }
  `);

  const cmsData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  return (
    <Section id="testimonial">
      <Container width="1400px">
        <SectionHeading>
          <Heading content={get(cmsData, "testimonials.title", "")} />
        </SectionHeading>
        <ReactSlick {...settings}>
          {get(cmsData, "testimonials.testimonials", [])?.map((item) => (
            <Item key={item?.id}>
              <Flex>
                <Box width="100px" mb="20px">
                  <GatsbyImage
                    image={getImage(item?.logo?.localFile)}
                    alt={item?.logo?.alternativeText}
                  />
                </Box>
                <Box ml="10px" mt="10px">
                  <CHeading
                    fontSize={16}
                    fontWeight={600}
                    color="black"
                    mb="5px"
                  >
                    {item?.testimonialPeopleName}
                  </CHeading>
                  <CText color="black" fontSize={14} textAlign="justify">
                    {item?.testimonialPeoplePosition}
                  </CText>
                </Box>
              </Flex>
              <CText as="blockquote">
                {parse(get(item, "content.data.content", ""))}
              </CText>
            </Item>
          ))}
        </ReactSlick>
      </Container>
    </Section>
  );
};

export default Testimonials;
