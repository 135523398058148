import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Container from "common/components/UI/Container";
import Text from "common/components/Text";
import Section, { Grid, Content, Features } from "./analytics.style";
import { get } from "lodash";
import { Text as ChakraText, Heading } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AnalyticsTool = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          exploreMore {
            outstandingTitle
            outstandingText
            outstandingDescription
            features {
              name
              id
            }
            photoLeft {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      placeholder: BLURRED
                      pngOptions: { compressionSpeed: 10, quality: 10 }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  `);
  const analyticsTool = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  return (
    <Section bgImageAlt="the cat" strength={200}>
      <Container width="1400px">
        <Grid>
          <Fade up>
            <GatsbyImage
              image={getImage(
                analyticsTool?.exploreMore?.photoLeft?.image.localFile
              )}
              alt={get(
                analyticsTool,
                "exploreMore.photoLeft.alt",
                "analyticsTool"
              )}
            />
          </Fade>
          <Content>
            <Text
              className="subtitle"
              content={analyticsTool?.exploreMore?.outstandingText}
            />
            <Heading
              color="#333"
              as="h2"
              fontSize={{ base: "24px", sm: "32px", lg: "36px" }}
            >
              {analyticsTool?.exploreMore?.outstandingTitle}
            </Heading>
            <Text
              className="description"
              content={analyticsTool?.exploreMore?.outstandingDescription}
            />
            <Features>
              {analyticsTool?.exploreMore?.features?.map((item, i) => (
                <li key={item?.id}>
                  <Icon
                    icon={x}
                    size={22}
                    style={{ color: "red", marginRight: "8px" }}
                  />
                  <ChakraText as="span" color="black">
                    {item.name}
                  </ChakraText>
                </li>
              ))}
            </Features>
            {/* <Link
              to={get(analyticsTool, "exploreMore?.button?.url", "#")}
              className="explore"
            >
              <Button
                title={analyticsTool?.exploreMore?.button?.name}
                icon={<Icon icon={ic_keyboard_arrow_right} size={24} />}
              />
            </Link> */}
          </Content>
        </Grid>
      </Container>
    </Section>
  );
};

export default AnalyticsTool;
