import React, { useState } from "react";
import Container from "common/components/UI/Container";
import Section from "./cta.style";
import { useStaticQuery, graphql } from "gatsby";
import { get } from "lodash";
import {
  Heading,
  Input,
  InputGroup,
  Text,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { defaultParams } from "../../../common/hooks/useGetUtm";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CallToAction = ({ lang }) => {
  const [phone, setPhone] = useState("");
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const urlSearchParams = !!window.location.search
      ? new URLSearchParams(window.location.search)
      : "";
    const params = !!urlSearchParams
      ? Object.fromEntries(urlSearchParams.entries())
      : defaultParams;
    try {
      const result = await axios.post(
        "https://cms.kaio.ai/api/phone-contacts",
        {
          data: {
            phone,
            ...params,
          },
        }
      );
      if (result.data !== null && !!result.data) {
        toast({
          title:
            "Thank you for your interest in Kaio. We will contact you soon.",
          status: "success",
          position: "top-right",
        });
      }
    } catch (err) {
      toast({
        title: "Có lỗi xảy ra",
        status: "error",
        position: "top-right",
      });
    } finally {
      setPhone("");
    }
  };

  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          footerTop {
            button {
              name
            }
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    placeholder: BLURRED
                    pngOptions: { compressionSpeed: 10, quality: 10 }
                    layout: CONSTRAINED
                  )
                }
              }
              alternativeText
            }
            placeholder
            description
            title
          }
        }
      }
    }
  `);

  const cmsData = allStrapiHomePage?.nodes?.filter(
    (node) => node.locale === lang
  )[0];

  return (
    <Section>
      <Container width="1400px">
        <Flex>
          <Flex
            flexDir="column"
            justifyContent="flex-end"
            flexShrink={0}
            width={{ base: "100%", lg: "50%" }}
            mt="20px"
            px="10px"
          >
            <Heading
              as="h2"
              fontSize={{ base: "24px", lg: "36px" }}
              color="primary"
              mb="6px"
            >
              {get(cmsData, "footerTop.title", "")}
            </Heading>
            <Text fontSize={14}>
              {get(cmsData, "footerTop.description", "")}
            </Text>
            <form onSubmit={handleSubmit}>
              <InputGroup
                bgColor="#f1f4f6"
                borderRadius="8px"
                height="60px"
                fontSize="16px"
                alignItems="center"
                mt="20px"
              >
                <Input
                  type="tel"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  placeholder={get(cmsData, "footerTop.placeholder", "")}
                  isRequired={true}
                  pattern="[0-9]{10}"
                  height="100%"
                  width="100%"
                  border="none"
                  px="24px"
                  ml="10px"
                  focusBorderColor="none"
                  fontSize={{ base: 12, lg: 14 }}
                />
                <Button
                  type="submit"
                  height="50px"
                  bgColor="primary"
                  color="#fff"
                  fontWeight="500"
                  fontSize={{ base: 12, lg: 14 }}
                  px="30px"
                  mx="10px"
                  _hover={{}}
                >
                  {get(cmsData, "footerTop.button.name", "")}
                </Button>
              </InputGroup>
            </form>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            display={{ base: "none", lg: "flex" }}
          >
            <GatsbyImage
              image={getImage(get(cmsData, "footerTop.cover.localFile"))}
              alt={get(cmsData, "footerTop.cover.alternativeText", "")}
            />
          </Flex>
        </Flex>
      </Container>
    </Section>
  );
};

export default CallToAction;
