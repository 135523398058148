import React from "react";
import Slider from "react-slick";
import Container from "common/components/UI/Container";
import Text from "common/components/Text";
import Section, { Title, SliderWrapper, Item } from "./clients.style";
import { Box } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { get } from "lodash";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
      // settings: "unslick",
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const Clients = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          companies {
            logos {
              id
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      placeholder: BLURRED
                      pngOptions: { compressionSpeed: 10, quality: 10 }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
              alt
            }
            title
          }
        }
      }
    }
  `);

  const clientsData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  return clientsData ? (
    <Section>
      <Container width="1400px">
        <Title>
          <Text content={clientsData?.companies?.title} />
        </Title>
        <SliderWrapper>
          <Slider {...settings}>
            {get(clientsData, "companies.logos", [])?.map((merchant) => (
              <Item key={merchant?.id}>
                {!!merchant?.image && (
                  <Box width="120px" height="120px">
                    <GatsbyImage
                      image={getImage(merchant?.image?.localFile)}
                      alt={merchant?.alt}
                    />
                  </Box>
                )}
              </Item>
            ))}
          </Slider>
        </SliderWrapper>
      </Container>
    </Section>
  ) : (
    <></>
  );
};

export default Clients;
